jQuery( document ).ready( function( $ ) {

    // Init the Carousel
    const carousel = $( '.carousel-container' ).slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    // Carousel Nav
    $('.carousel-prev').on('click', () => {
        $('.carousel-container').slick('slickPrev');
    });
    $('.carousel-next').on('click', () => {
        $('.carousel-container').slick('slickNext');
    });

    // Track the current position of the slider
    carousel.on('afterChange', (event, slick, currentSlide) => {
        const totalSlides = slick.slideCount;
        const progressBarWidth = (currentSlide / (totalSlides - 1)) * 100;
        $('.progress-bar-inner').css('width', `${progressBarWidth}%`);
    });
    
});